import React from 'react';
import styled from 'styled-components'
import Logo from '../../molecules/share/Logo'
import SideItem from '../../molecules/share/SideItem'
import SideLink from '../../molecules/share/SideLink'
import { signOutCompany } from '../../../api/company'

export const AttendanceItems = [
  { name: '勤務状況', path: '/management' },
  { name: 'CSVダウンロード', path: '/management/csv_download' }
]
export const StaffItems = [
  { name: '従業員一覧', path: '/management/staff' },
  { name: '従業員新規登録', path: '/management/staff/new' }
]
export const SettingItems = [
  { name: 'グループ設定', path: '/management/departments/new' },
  { name: '従業員種別設定', path: '/management/employment_statuses/new' },
  { name: '所定・残業時間設定', path: '/management/working_times' },
  { name: '自動休憩設定', path: '/management/break_times' }
]

const signOut = () => {
  signOutCompany()
    .then(() => {
      document.location.href = '/'
    })
    .catch((res) => {
      window.alert(res.response.data.message)
    })
}

export const SideList = () => {
  return (
    <SideListArea>
      <LogoArea>
        <Logo />
      </LogoArea>
      <List>
        <SideItem title='出勤管理' items={AttendanceItems} />
        <SideItem title='従業員管理' items={StaffItems} />
        <SideLink title='休暇・申請管理' path='/management/requests' />
        <SideItem title='基本情報設定' items={SettingItems} />
        <Logout onClick={signOut}>ログアウト</Logout>
      </List>
    </SideListArea>
  )
}

const LogoArea = styled.div`
  margin-top: 40px;
`
const SideListArea = styled.div`
  background: rgba(255, 255, 255, 0.7);
  min-height: 93vh;
  height: 100%;
  width: 15vw;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: bold;
`
const List = styled.div`
  margin-top: 75px;
`
const Logout = styled.p`
  cursor: pointer;
`

export default SideList
