import React from 'react'
import styled from 'styled-components'
import TopBanner from 'images/home/top_banner.jpg'

export const Top = () => {
  return (
    <TopArea>
      <HeroArea>
        <TitleArea>
          <Title>WORKOPT</Title>
          <SubTitle>勤怠管理を効率化、その一歩先へ</SubTitle>
        </TitleArea>
      </HeroArea>
      {/* <Img src={TopBanner} /> */}
      <TextArea>
        <Content>
          WORKOPTは従業員の勤怠管理を一元管理するWebサービスです。<br />
          守りの業務をDX化して、業務効率UPをサポートいたします。
        </Content>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="11.833" height="46.876" viewBox="0 0 11.833 46.876">
          <g id="グループ_4" data-name="グループ 4" transform="translate(-934 -1308.955)">
            <path id="パス_78" data-name="パス 78" d="M21.114,38.969a2.451,2.451,0,0,1-4.529,1.3L10,33.682a2.451,2.451,0,1,1,3.468-3.465l2.745,2.746v-18.5a2.451,2.451,0,1,1,4.9,0V38.967Z" transform="translate(924.719 1296.945)" fill="#9ba4b4" />
            <path id="パス_77" data-name="パス 77" d="M19.476,8.441A2.451,2.451,0,0,1,24,7.141l6.587,6.586a2.451,2.451,0,0,1-3.468,3.465l-2.745-2.746v18.5a2.451,2.451,0,1,1-4.9,0V8.441Z" transform="translate(914.524 1320.432)" fill="#14274e" />
          </g>
        </svg> */}
      </TextArea>
    </TopArea>
  )
}

const TopArea = styled.div`
  margin: 0;
  width: 100%;
`
const HeroArea = styled.div`
  width: 100%;
  height: 780px;
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
const TitleArea = styled.div`
  grid-column: span 1 / span 1;
  margin: auto;
`
const Title = styled.h1`
  font-size: 5rem;
  color: #ffffff;
`
const SubTitle = styled.h2`
  font-size: 1.5rem;
  color: #ffffff;
`
const Img = styled.img`
  width: 100%;
`
const TextArea = styled.div`
  text-align: center;
  margin-bottom: 100px;
`
const Content = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  margin: 33px 0 100px;
`

export default Top
