import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import EmploymentStatusForm from '../../../organisms/management/employment_statuses/EmploymentStatusForm'

export interface Props {
  employment_status?: {
    id: number
    name: string
  }
}

export const Edit: React.FC<Props> = ({ employment_status }) => {
  return (
    <EditArea>
      <TitleArea>
        <Title value="従業員種別編集" />
      </TitleArea>
      <EmploymentStatusForm employment_status={employment_status} />
    </EditArea>
  )
}

export const EditArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 auto;
  padding: 1rem 2rem 1rem 1rem;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  box-shadow: 0 0 1rem #ffffff;
`
const TitleArea = styled.div`
  width: 100%;
  text-align: center;
`

export default Edit
