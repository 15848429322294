import React from 'react';
import styled from 'styled-components'
import Title from '../../atoms/share/Title'
import ModifyTimestampForm from '../../organisms/employees/ModifyTimestampForm'

export const Modify = () => {
  return (
    <ModifyArea>
      <ModifyRequestArea>
        <Title value='打刻修正' />
        <ModifyTimestampForm />
      </ModifyRequestArea>
    </ModifyArea>
  )
}

const ModifyArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ModifyRequestArea = styled.div`
  width: 80%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  box-shadow: 0 0 1rem #ffffff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`

export default Modify
