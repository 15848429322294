import React from 'react';
import styled from 'styled-components'

export interface Props {
  items: string[]
}

export const TableHead: React.FC<Props> = ({ items }) => {
  return (
    <HeadArea>
      <Row>
        {items.map((item, index) => (
          <Head key={index}>{item}</Head>
        ))}
      </Row>
    </HeadArea>
  )
}

const HeadArea = styled.thead`
  display: table-row-group;
`
const Row = styled.tr`
`
const Head = styled.th`
  background: #000000;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  padding: 5px;
  text-align: unset;
  min-width: 10rem;
`

export default TableHead
