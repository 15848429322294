import React from 'react'
import styled from 'styled-components'
import Logo from '../../molecules/share/Logo'
import SignupButton from '../../atoms/SignupButton'
import LoginButton from '../../atoms/LoginButton'

export const Header = () => {
  return (
    <HeaderArea>
      <Logo />
      <Buttons>
        <SignupButton />
        <LoginButton />
      </Buttons>
    </HeaderArea>
  )
}

const HeaderArea = styled.header`
  display: flex;
  width: 100%;
  height: 80px;
  position: fixed;
  background:  rgba(255,255,255, 0.5);
  box-sizing: border-box;
  justify-content: space-between;
  padding: 20px 80px;
  align-items: center;
`
const Buttons = styled.div`
  align-items: center;
  display: flex;
  width: 30%;
`

export default Header
