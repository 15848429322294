import React from 'react';
import styled from 'styled-components'

export interface Props {
  title: string;
  path: string
}

export const SiteLink: React.FC<Props> = ({ title, path }) => {
  return (
    <SideLinkArea>
      <Link href={path}>{title}</Link>
    </SideLinkArea>
  )
}

const SideLinkArea = styled.div`
  margin-bottom: 20px;
`
const Link = styled.a`
  text-decoration: none;
  color: #000000;
`

export default SiteLink
