import React from 'react'
import styled from 'styled-components'
import summary1 from 'images/home/summary/1.png'
import summary2 from 'images/home/summary/2.png'
import summary3 from 'images/home/summary/3.png'

export const Summary = () => {
  return (
    <SummaryArea>
      <Title>WORKOPT 3つのPOINT</Title>
      <List>
        <Item>
          <Img1 src={summary1} />
          <TextArea>
            <Subheading>
              従業員の勤怠を一括管理
            </Subheading>
            <Content>
              各従業員それぞれが各自のアカウントから勤怠を入力することで、<br />
              手間だったタイムカードの集計等から解放され、自動集計・出力されます。<br />
              管理者は従業員を一覧で確認できるため、大きな業務改善が見込めます。
            </Content>
          </TextArea>
        </Item>
        <Item>
          <Img2 src={summary2} />
          <TextArea>
            <Subheading>
              勤怠・有休情報等をCSV形式で出力
            </Subheading>
            <Content>
              各従業員の勤怠情報をCSV形式で出力することが可能です。<br />
              独自の集計方法や別サービスへの出力が容易に実現できます。
            </Content>
          </TextArea>
        </Item>
        <Item>
          <Img3 src={summary3} />
          <TextArea>
            <Subheading>
              アラート機能
            </Subheading>
            <Content>
              残業時間や有休設定などを登録することで、<br />
              残業時間を超えた従業員等に対してアラートされます。<br />
              これにより従業員の超過労働を自動抑制することが可能です。
            </Content>
          </TextArea>
        </Item>
      </List>
    </SummaryArea>
  )
}

const SummaryArea = styled.div`
  background: #e0ffff;
`
const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  padding: 100px 0 25px 0;
  text-align: center;
  color: #14274E;
`
const List = styled.div`
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 100px;
`
const Item = styled.div`
  text-align: center;
  padding: 0 10px 10px 50px;
`
const Img1 = styled.img`
  margin: auto;
  width: auto;
  height: 150px;
`
const Img2 = styled.img`
  margin: auto;
  width: auto;
  height: 150px;
`
const Img3 = styled.img`
  margin: auto;
  width: auto;
  height: 150px;
`
const TextArea = styled.div`
  background: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 30px 50px;
  text-align: center;
`
const Subheading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
const Content = styled.div`
  font-size: 18px;
`


export default Summary
