import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import StatusTable from '../../molecules/management/work_status/StatusTable'
import { fetchWorkStatus } from '../../../api/work_status'

export const WorkStatus = () => {
  const [countEmployee, setCountEmployee] = useState(null)
  const [totalWorkingTime, setTotalWorkingTime] = useState(null)
  const [averageWorkingTime, setAverageWorkingTime] = useState(null)
  const [totalOverWorkingTime, setTotalOverWorkingTime] = useState(null)
  const [averageOverWorkingTime, setAverageOverWorkingTime] = useState(null)
  const [totalUnpaidWorkingTime, setTotalUnpaidWorkingTime] = useState(null)
  const [averageUnpaidWorkingTime, setAverageUnpaidWorkingTime] = useState(null)

  useEffect(() => {
    fetchWorkStatus()
      .then(
        (res) => { 
          const data = res.data
          setCountEmployee(data.count_employees)
          setTotalWorkingTime(data.total_working_time)
          setAverageWorkingTime(data.average_working_time)
          setTotalOverWorkingTime(data.total_over_working_time)
          setAverageOverWorkingTime(data.average_over_working_time)
          setTotalUnpaidWorkingTime(data.total_unpaid_working_time)
          setAverageUnpaidWorkingTime(data.average_unpaid_working_time)
        }
      )
      .catch(
        () => { 
          window.alert('データの取得に失敗しました')
        }
      )
  }, []);

  return (
    <WorkStatusArea>
      <CountEmployee>
        登録従業員数<Count>{countEmployee}人</Count>
      </CountEmployee>
      <TableSection>
        <StatusTable title="全体月次勤務時間" totalName="全体月次勤務時間" totalTime={totalWorkingTime} averageName="平均" averageTime={averageWorkingTime} />
        <StatusTable title="全体残業時間" totalName="全体残業時間" totalTime={totalOverWorkingTime} averageName="平均" averageTime={averageOverWorkingTime}　/>
        <StatusTable title="全体超過労働時間" totalName="全体超過労働時間" totalTime={totalUnpaidWorkingTime} averageName="平均" averageTime={averageUnpaidWorkingTime} />
      </TableSection>
    </WorkStatusArea>
  )
}

const WorkStatusArea = styled.div`
`
const CountEmployee = styled.p`
  font-size: 16px;
`
const Count = styled.span`
  margin-left: 40px;
`
const TableSection = styled.div`
  display: flex;
  justify-content: space-between;
`

export default WorkStatus
