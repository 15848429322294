import React from 'react';
import styled from 'styled-components'

export const SectionTitle: React.FC = ({ children }) => {
  return (
    <SectionTitleArea>
      {children}
    </SectionTitleArea>
  )
}

const SectionTitleArea = styled.div`
  border: 2px solid #9BA4B4;
  box-sizing: border-box;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
`

export default SectionTitle
