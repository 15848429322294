import React from 'react'
import styled from 'styled-components'

export const LoginButton = () => {
  return (
    <Login href='/companies/sign_in'>
      ログイン
    </Login>
  )
}

const Login = styled.a`
  display: flex;
  color: #ffffff;
  background: #008000;
  border: 2px solid #006400;
  cursor: pointer;
  font-size: 1rem;
  height: 50px;
  padding: 10px 16px;
  align-items: center;
  width: 100%;
  min-width: 2rem;
  vertical-align: middle;
  border-radius: 30px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  margin: 0px 20px;
  justify-content: space-around;
  letter-spacing: 0.1rem;
  &:hover {
    color: #000000;
    background-color:transparent;
  }
`

export default LoginButton
