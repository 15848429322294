import React from 'react'
import styled from 'styled-components'
// import { PrimaryButton } from 'smarthr-ui'
// <PrimaryButton>Hello World</PrimaryButton>

export const Footer = () => {
  return (
    <FooterArea>
      <Top>
        <Title>WORKOPT<TitleInner>|</TitleInner>勤怠管理を効率化、その一歩先へ</Title>
      </Top>
      <MiddleContent>
        運営会社
        <Middle href='https://d-inginc.com/' target={'_blank'} rel='no-referrer'>
          株式会社D-ing
        </Middle>
      </MiddleContent>
      <Bottom>
        プライバシー | 利用規約
      </Bottom>
    </FooterArea>
  )
}

const FooterArea = styled.footer`
  height: 4rem;
  background: rgba(255, 255, 255, 0.5);
  color: #14274E;
  font-size: 20px;
  padding: 20px 40px;
  text-align: center;
`
const Top = styled.div`
`
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: auto;
`
const TitleInner = styled.span`
  margin: 0 10px;
`
const MiddleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 20px 0 50px 0;
`
const Middle = styled.a`
  display: block;
  font-size: 18px;
  margin-left: 10px;
`
const Bottom = styled.div`
  display: none;
  font-size: 18px;
`


export default Footer
